<template>
  <div
      id="gradient-video-wrapper"
      :class="open ? 'stack-grid is-open' : 'stack-grid is-closed'"
  >
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: "desktopWrapper",
  props:{
    open: Boolean
  }
}
</script>
