export const resizable = {
  data() {
    return {
      maximize: false
    }
  },
  computed: {
    maxWidth() {
      console.log(window.innerWidth)
      return window.innerWidth - 132
    },
    maxHeight() {
      console.log(window.innerHeight)
      return window.innerHeight - 100
    },
    makeSize() {
      return this.windowed ? {w:window.innerWidth,h:window.innerHeight} : this.maximize ? {w:this.maxWidth,h:this.maxHeight, l:-(this.maxWidth-128),t:-(this.maxHeight-128)} : {w:600,h:400,l:-472,t:-272}
    }
  },
}
