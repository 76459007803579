<template>
  <div id="closed-display" class="two-column-wrap">
    <div class="col-wrap left-wrap">
      <p class="text-space welcome-text">Sorry We Missed You!</p>
      <p style="text-align: center; margin-bottom: 18px">Leave your info so we can reach out to you as soon as we can, or stop by during our next open hours!</p>
      <ValidationObserver v-slot="{ invalid }" style="width: 100%">
        <validation-provider name="email" rules="required|email" v-slot="{ errors }">
          <b-field label-position="inside" class="space-bottom" label="Email" :message="errors[0]" :type="errors[0] ? 'is-danger' : ''">
            <b-input :disabled="sent" type="email" placeholder="email@mail.com" v-model="email"></b-input>
          </b-field>
        </validation-provider>
        <b-button :disabled="invalid || sent" :loading="sending" type="is-dark" expanded size="is-large" class="start-button" @click="handleClick">{{sent ? 'Sent!' : 'Contact Me'}}</b-button>
        <p v-if="sent" style="text-align: center; margin-top: 8px; color:#363636">Thanks! We will contact you shortly.</p>
        <p v-if="error" style="text-align: center; margin-top: 8px; color:#CC0F35">Problem sending message, <br> please try again in a minute.</p>
      </ValidationObserver>
    </div>
    <div class="col-wrap right-wrap stack-grid">
      <div class="side-image-overlay"></div>
      <table class="middle-text">
        <thead style="font-size:1.2em">
        <tr>
          <td colspan="2" style="padding-bottom: 10px">Open Hours</td>
        </tr>
        </thead>
        <tbody>
          <tr v-for="item in checkInfo">
            <td style="text-align: end">{{formatDay(item.day)}} :</td>
            <td style="text-align: start; padding-left: 5px">{{makeTimeString(item)}}</td>
          </tr>
        </tbody>
      </table>
      <div class="version-wrap">
        <p class="version-text">Version: {{getVersion()}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {_missedCall} from "@/api/hiApi";
import PreviewAgent from "../assets/PreviewAgent.png"
import {capitalize, split} from 'lodash'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';
extend('email', {
  ...email,
  message: 'Please enter a valid email'
});

export default {
  name: "closedDisplay",
  keepAlive: false,
  components:{
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    info: Object,
    mobile: {
      type: Boolean,
      required: false
    },
    accountId: String,
  },
  data() {
    return {
      PreviewAgent,
      format: 'HH:mm:ss',
      email:'',
      sending:false,
      sent:false,
      error:false,
    }
  },
  computed: {
    checkInfo() {
      return this.info ? this.info.accountProperties.availability : []
    }
  },
  methods: {
    getVersion() {
      return process.env.VUE_APP_BUILD_VERSION;
    },
    formatDay(day) {
      return capitalize(day)
    },
    makeTimeString(item) {
      if (!item.enabled) {
        return "Closed"
      } else {
        let {opensAt,closesAt} = item
        let open = Number(split(opensAt,":")[0])
        let close = Number(split(closesAt,":")[0])
        return `${open % 12} ${open > 12 ? 'PM' : 'AM'} - ${close % 12} ${close > 12 ? 'PM' : 'AM'}`
      }
    },
    async handleClick() {
      this.error = false
      this.sending = true
      try {
        await _missedCall(this.accountId,{email:this.email})
        this.sending = false
        this.sent = true
      } catch (e) {
        this.sending = false
        this.error = true
        console.error('problem sending message:',e)
      }
    }
  }
}
</script>
