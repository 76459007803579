<template>
  <div id="plugin" :class="makeAppClass" :style="visStyle">
    <start-button @open="open = true" :open="open"></start-button>
    <desktop-wrapper
      v-if="isDesktop"
      :open="open"
      :class="showClose ? '' : 'invisible-wrapper'"
    >
      <div v-if="loadErr" class="error-message">
        <h1>Uh oh!</h1>
        <p>
          Unfortunately, it looks like we don't have enough information to
          connect you to your chat. Feel free to close this window and try
          again, or reach out via an alternative method to get assistance. We
          are sorry for the inconvenience!
        </p>
        <button @click="handleClose">Close</button>
      </div>
      <closed-display
        v-else-if="!openHours"
        :mobile="false"
        :info="acctInfo"
        :account-id="idSwitch"
      ></closed-display>
      <video-wrapper
        v-else
        :supported="isSupported"
        :windowed="windowed"
        :error="loadErr"
        :mobile="false"
        :open="open"
        :data="acctInfo"
        :account-id="idSwitch"
        @setActive="setActive"
        @close="handleClose"
        @showClose="handleShowClose"
      ></video-wrapper>
      <b-button
        v-if="!isActive && !windowed"
        @click="open = false"
        rounded
        class="btn-with-icon close-button"
        style="z-index: 10"
      >
        <closeIcon v-show="showClose" class="close-button-icon"></closeIcon>
      </b-button>
    </desktop-wrapper>
    <mobile-wrapper v-else :open="open" class="is-mobile">
      <div v-if="loadErr" class="error-message">
        <h1>Uh oh!</h1>
        <p>
          Unfortunately, it looks like we don't have enough information to
          connect you to your chat. Feel free to close this window and try
          again, or reach out via an alternative method to get assistance. We
          are sorry for the inconvenience!
        </p>
        <button @click="handleClose">Close</button>
      </div>
      <closed-display
        v-else-if="!openHours"
        :mobile="true"
        :info="acctInfo"
        :account-id="idSwitch"
      ></closed-display>
      <video-wrapper
        v-else
        :supported="isSupported"
        :windowed="windowed"
        :error="loadErr"
        :mobile="true"
        :open="open"
        :data="acctInfo"
        :account-id="idSwitch"
        @close="handleClose"
        @showClose="handleShowClose"
      ></video-wrapper>
      <b-button
        v-if="!isActive && !windowed"
        @click="open = false"
        rounded
        class="btn-with-icon close-button"
        style="z-index: 10"
      >
        <closeIcon v-show="showClose" class="close-button-icon"></closeIcon>
      </b-button>
    </mobile-wrapper>
  </div>
</template>
<script>
import Vue from "vue";
import Buefy from "buefy";
import StartButton from "./components/startButton.vue";
import VideoWrapper from "./components/videoWrapper.vue";
import DesktopWrapper from "@/components/wrappers/desktopWrapper";
import MobileWrapper from "@/components/wrappers/mobileWrapper";
import closedDisplay from "@/components/closedDisplay";
import closeIcon from "./assets/CloseCircle.svg";
import { authenticatePlugin } from "@/api/hiApi";
import { isSupported } from "twilio-video";
import dayjs from "dayjs";
import Bowser from "bowser";

Vue.use(Buefy);

export default {
  name: "Plugin",
  components: {
    MobileWrapper,
    DesktopWrapper,
    VideoWrapper,
    closedDisplay,
    StartButton,
    closeIcon,
  },
  props: {
    api: {
      type: String,
    },
    id: {
      type: String,
    },
    developer: {
      type: Boolean,
      required: false,
    },
    windowed: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      visible: false,
      openHours: false,
      visStyle: "display:none;opacity:0",
      open: false,
      isDesktop: true,
      acctInfo: null,
      secrets: null,
      visibilityTimer: null,
      isActive: false,
      showClose: true,
      loadErr: false,
      tempApi: process.env.VUE_APP_KEY,
      tempId: process.env.VUE_APP_ID,
      isSupported,
    };
  },
  methods: {
    async initPlugin() {
      const authData = {
        accountId: this.idSwitch,
        apiKey: this.apiSwitch,
      };
      await authenticatePlugin(authData, this.developer)
        .then((res) => {
          this.loadErr = false;
          this.acctInfo = res["accountInfo"];
          this.checkVis(res["accountInfo"]);
        })
        .catch((err) => {
          this.loadErr = true;
          console.error("dang! Something went wrong.", err);
          if (this.windowed) {
            this.open = true;
            this.showPlugin();
          }
        });
    },
    checkVis(data) {
      const { isOpen, nextOpen, nextClose } = data;
      console.log('checking',data)
      if (isOpen) {
        this.openHours = true
        this.showPlugin();
      } else if (nextOpen) {
        this.openHours = false
        this.showPlugin();
      }
      if (nextOpen || nextClose) {
        const val =
          dayjs(nextOpen ? nextOpen : nextClose).valueOf() - dayjs().valueOf();
        this.visibilityTimer = setTimeout(() => {
          this.initPlugin();
        }, val);
      }
      this.showPlugin();
    },
    checkScreen() {
      const browser = Bowser.getParser(window.navigator.userAgent);
      let platform = browser.getPlatformType(true);
      if (platform === "mobile") {
        this.isDesktop = false;
      } else this.isDesktop = !(screen.width < 768 || window.innerWidth < 768);
    },
    setActive(val) {
      this.isActive = val;
    },
    handleShowClose(val) {
      this.showClose = val;
    },
    handleClose() {
      if (this.windowed) {
        window.close();
      } else this.open = false;
    },
    showPlugin() {
      if (this.windowed) {
        this.open = true;
      }
      this.visible = true;
      this.visStyle = "display:grid; opacity:0";
      setTimeout(() => {
        this.visStyle = "display:grid; opacity:1";
      }, 100);
    },
    hidePlugin() {
      this.visStyle = "display:grid; opacity:0";
      setTimeout(() => {
        this.visStyle = "display:none; opacity:0";
      }, 600);
    },
  },
  computed: {
    apiSwitch() {
      return process.env.NODE_ENV === "development" ? this.tempApi : this.api;
    },
    idSwitch() {
      return process.env.NODE_ENV === "development" ? this.tempId : this.id;
    },
    makeAppClass() {
      if (this.windowed) {
        return "wrapper-is-windowed";
      } else return this.open ? "wrapper-is-open" : "wrapper-is-closed";
    },
  },
  created() {
    this.initPlugin();
  },
  mounted() {
    if (isSupported) {
      this.checkScreen();
    } else {
      console.error(
        "Hi There Error: This browser is not supported. This may cause performance issues."
      );
      this.checkScreen();
    }
  },
  destroyed() {
    clearTimeout(this.visibilityTimer);
    // window.removeEventListener("resize", this.checkScreen);
  },
};
</script>

<style lang="css">
@import "~normalize.css";
@import "~buefy/dist/buefy.css";
</style>

<style lang="scss">
@import "./assets/scss/app";
@import "./assets/scss/style";

@font-face {
  font-family: "DM Sans";
  src: url("./assets/DMSans-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "DM Sans";
  src: url("./assets/DMSans-Bold.ttf");
  font-weight: bold;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
em,
strong,
a {
  font-family: "DM Sans", Avenir, Helvetica, Arial, sans-serif !important;
  text-transform: none;
}
#plugin {
  z-index: 999999999;
  transition: width 1s ease-in-out, height 1s ease-in-out,
    opacity 0.5s ease-in-out, bottom 1s ease-in-out, right 1s ease-in-out;
  font-family: "DM Sans", Avenir, Helvetica, Arial, sans-serif;
  display: grid;
  position: fixed;
  bottom: 50px;
  right: 50px;
  & > * {
    grid-area: 1/-1;
  }
}
</style>
