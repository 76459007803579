import axios, { AxiosError, AxiosResponse } from "axios";
import { WidgetLoginHelper } from "./WidgetLoginHelper";

type authBody = {
  accountId: string;
  apiKey: string;
};
type callType = {
  url: string;
  type: "post" | "put" | "get" | "delete";
  body?: any;
  params?: any;
  headers?: any;
};
type bodyObj = {
  firstName: string;
  lastName: string;
  email: any;
};
type emailObj = {
  email: string;
};

const devHosts = [
  "dev.env.tryhithere.com",
  "localhost",
  "hi-there-d89927.webflow.io",
];

// const isDev = () => {
//   let base = window.location.hostname;
//   if (base.startsWith("192.168")) {
//     return true;
//   } else return devHosts.includes(base);
// };

let hiApi = axios.create({
  baseURL: "https://portal.hi-there.video/api/v1",
});
const headers = {
  "Content-Type": "application/json",
};

// Initial app authorization
export async function authenticatePlugin(
  { accountId, apiKey }: authBody,
  developer: Boolean,
) {
  if (developer) {
    hiApi = axios.create({
      baseURL: "https://dev.env.tryhithere.com/api/v1",
    });
  }
  return new Promise((resolve, reject) => {
    const hashedKey = WidgetLoginHelper.createLoginHash(accountId, apiKey);
    const widgetLoginRequest = {
      accountId: accountId,
      hashedKey: hashedKey,
    };
    hiApi
      .post("/widget/authenticate", JSON.stringify(widgetLoginRequest), {
        headers,
      })
      .then((res: AxiosResponse<any>) => {
        localStorage.setItem("hi-token", res.data["token"]);
        localStorage.setItem("hi-refresh-token", res.data["refreshToken"]);
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Internal call handlers to enable refresh
async function refreshPlugin() {
  return new Promise((resolve, reject) => {
    hiApi
      .post("/widget/refresh", localStorage.getItem("hi-refresh-token"))
      .then((res: AxiosResponse<any>) => {
        localStorage.setItem("hi-token", res.data["token"]);
        localStorage.setItem("hi-refresh-token", res.data["refreshToken"]);
        resolve(res.data["token"]);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
async function makeCall(
  { url, type, body, params, headers }: callType,
  token?: string
) {
  headers = {
    "ACCOUNT-ID": headers.accountId,
    Authorization: `Bearer ${token ? token : localStorage.getItem("hi-token")}`,
  };

  let call: any;
  switch (type) {
    case "get":
      call = hiApi.get(url, { headers, params });
      break;
    case "put":
      call = hiApi.put(url, body, { headers, params });
      break;
    case "post":
      call = hiApi.post(url, body, { headers, params });
      break;
    case "delete":
      call = hiApi.delete(url, { headers, params });
  }
  return new Promise((resolve, reject) => {
    call
      .then((res: AxiosResponse<any>) => {
        resolve(res);
      })
      .catch((err: AxiosError<any>) => {
        reject(err);
      });
  });
}
// Currently this never makes the refresh call since the endpoint isn't set up
async function makeCallWithRefresh(callObject: callType) {
  return new Promise((resolve, reject) => {
    makeCall(callObject)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: AxiosError<any>) => {
        // @ts-ignore
        if (err.response.status && err.response.status === (401 || 403)) {
          refreshPlugin()
            .then((resToken: any) => {
              makeCall(callObject, resToken)
                .then((res: any) => resolve(res))
                .catch((err: any) => reject(err));
            })
            .catch((err: any) => reject(err));
        } else reject(err);
      });
  });
}

// Plugin API Calls
export async function _registerCall(accountId: string, body: bodyObj) {
  return new Promise((resolve, reject) => {
    makeCallWithRefresh({
      url: "/widget/calls/register-call",
      type: "post",
      body,
      headers: { accountId },
    })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}
export async function _getCall(accountId: string, id: string) {
  return new Promise((resolve, reject) => {
    makeCallWithRefresh({
      url: "/widget/calls/register-call",
      type: "post",
      params: { id },
      headers: { accountId },
    })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}
export async function _keepAlive(accountId: string, id: string) {
  return new Promise((resolve, reject) => {
    makeCallWithRefresh({
      url: "/widget/calls/keep-alive",
      type: "put",
      params: { id },
      headers: { accountId },
    })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}
export async function _endCall(accountId: string, id: string) {
  return new Promise((resolve, reject) => {
    makeCallWithRefresh({
      url: "/widget/calls/end-call",
      type: "post",
      params: { id },
      headers: { accountId },
    })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}
export async function _missedCall(accountId: string, body: emailObj) {
  return new Promise((resolve, reject) => {
    makeCall({
      url: "/widget/submit-missed-call-notification",
      type: "post",
      body,
      headers: { accountId },
    })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}
