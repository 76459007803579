<template>
  <div class="two-column-wrap">
    <div class="col-wrap left-wrap thank-you-stack">
      <h3 class="text-space welcome-text">Call Ended</h3>
      <p class="text-space">Thank you for connecting with us!</p>
      <b-button @click="$emit('close')">Close</b-button>
      <b-button class="is-text" style="margin-top: 5px; text-decoration: none" @click="$emit('restart')">Start A New Call</b-button>
    </div>
    <div v-if="mobile" class="col-wrap right-wrap mobile-text-wrap">
    </div>
    <div v-else class="col-wrap right-wrap stack-grid">
      <b-image :src="PreviewAgent" class="limit-desktop-height" :style="windowed ? 'border-radius:0' : ''" ></b-image>
      <div class="side-image-overlay" :style="windowed ? 'border-radius:0' : ''"></div>
    </div>
  </div>
</template>

<script>
import PreviewAgent from "../assets/PreviewAgent.png"
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

// No message specified.
extend('email', {
  ...email,
  message: 'Please enter a valid email'
});

// Override the default message.
extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: "welcomeMessage",
  keepAlive: false,
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: {
    status: String,
    windowed: Boolean,
    mobile: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      PreviewAgent,
      firstName: "",
      lastName: "",
      email: "",
      showOverlay: false,
    }
  },
  computed: {
    statusMessage() {
      switch (this.status) {
        case "default":
          return "4 Agents Available";
        case "connecting":
          return "Connecting...";
        case "delay":
          return `Should be a few \n more seconds...`;
        case "failure":
          return `Sorry, we were \n unable to connect.`;
        default:
          return "Welcome!"
      }
    }
  },
  methods: {
    handleClick() {
      this.showOverlay = true
      this.status = "connecting"
      this.$emit('connect',{
        firstName:this.firstName,
        lastName:this.lastName,
        email:this.email
      })
    },
  }
}
</script>
