import Bowser from "bowser";
import { LocalVideoTrack } from "twilio-video";

export const screenshare = {
  data() {
    return {
      sharing: {
        stream: null,
        request: { sources: ["window", "screen", "tab"] },
        track: null,
        restoreVideo: false,
      },
    };
  },
  computed: {
    canScreenShare() {
      const browser = Bowser.getParser(window.navigator.userAgent);
      if (browser.getPlatformType(true) === "desktop") {
        return browser.satisfies({
          firefox: ">=66",
          chrome: ">=72",
          safari: ">=13",
          edge: ">=79",
          opera: ">=60",
        });
      } else return false;
    },
  },
  methods: {
    async startShare() {
      const stream = await navigator.mediaDevices.getDisplayMedia();
      stream.getTracks()[0].addEventListener("ended", () => this.endShare());
      let screenTrack = new LocalVideoTrack(stream.getTracks()[0]);
      this.sharing.track = screenTrack;
      if (this.video) {
        this.sharing.restoreVideo = true;
        this.handleVideoClick(false);
      }
      this.activeRoom.localParticipant.publishTrack(screenTrack);
    },
    endShare() {
      const track = [
        ...this.activeRoom.localParticipant.videoTracks.values(),
      ][0].track;
      track.stop();
      track.detach().forEach((element) => {
        element.remove();
      });
      this.activeRoom.localParticipant.unpublishTrack(track);
      this.sharing.track = null;
      this.share = false;
      if (this.sharing.restoreVideo) {
        this.handleVideoClick(true);
        this.sharing.restoreVideo = false;
      }
    },
  },
};
