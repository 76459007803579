<template>
  <div>
    <thank-you-message v-if="finished" :windowed="windowed" :mobile="mobile" @close="$emit('close')" @restart="handleRestart"></thank-you-message>
    <video-player-mobile v-else-if="connected && mobile" :defaults="defaults" :windowed="windowed" :room-name="callData.id" :identity="callData.identity" :token="callData.token" key="video-player" :forceFail="failOut" @ended="handleEnd"></video-player-mobile>
    <video-player v-else-if="connected && !mobile" :defaults="defaults" :windowed="windowed" :room-name="callData.id" :identity="callData.identity" :token="callData.token" key="video-player" :forceFail="failOut" @ended="handleEnd"></video-player>
    <welcome-message v-else :supported="supported" :windowed="windowed" :mobile="mobile" @defaults="handleDefaults" @connect="joinQueue" @cancel="leaveQueue" :status="status" :inputs="customInputs" key="welcome-message"></welcome-message>
  </div>
</template>

<script>
import welcomeMessage from "@/components/welcomeMessage";
import videoPlayer from "@/components/videoPlayer";
import videoPlayerMobile from "@/components/videoPlayerMobile";
import ThankYouMessage from "@/components/thankYouMessage";
import {api} from "@/mixins/api";

export default {
  name: "videoWrapper",
  mixins:[api],
  components: {
    ThankYouMessage,
    welcomeMessage,
    videoPlayer,
    videoPlayerMobile
  },
  props: {
    data: Object,
    mobile: Boolean,
    windowed: Boolean,
    error:Boolean,
    supported: Boolean,
  },
  data() {
    return {
      connected: false,
      statusTimer: null,
      bailTimer: null,
      status: "default",
      bail: false,
      finished: false,
      defaults: {
        video:true,
        audio:true
      },
    }
  },
  computed: {
    showClose() {
      if (this.connected) {
        return this.finished
      } else return true
    },
    customInputs() {
      return this.data.accountProperties.customFields && this.data.accountProperties.customFields.length > 0 ? this.data.accountProperties.customFields : []
    }
  },
  methods: {
    async joinQueue(data) {
      this.status="connecting"
      this.statusTimer = setTimeout(() => {
        this.status = "delay"
      },10000)
      this.bailTimer = setTimeout(() => {
        this.status = "bail"
      }, 20000)
      try {
        await this.registerCall(data)
      } catch (e) {
        clearTimeout(this.statusTimer)
        clearTimeout(this.bailTimer)
        console.error(e)
        this.status = 'failure'
      }
    },
    leaveQueue() {
      clearTimeout(this.statusTimer)
      clearTimeout(this.bailTimer)
      this.bailCall()
      this.handleRestart()
    },
    handleEnd() {
      this.finished = true
      this.endCall()
    },
    handleRestart() {
      this.status = "default"
      this.statusTimer = null
      this.bailTimer = null
      this.defaults.video = true
      this.defaults.audio = true
      this.connected = false
      this.finished = false
    },
    handleDefaults(vals) {
      this.defaults.video = vals.video
      this.defaults.audio = vals.audio
    }
  },
  watch: {
    showClose: function (n) {
      this.$emit('showClose',n)
    }
  }
}
</script>
