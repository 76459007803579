import {_registerCall,_getCall,_keepAlive,_endCall} from "@/api/hiApi";

export const api = {
  data() {
    return {
      callData: null,
      keepAliveTimer: null,
      failOut:false
    }
  },
  props: {
    accountId: String,
  },
  methods: {
    registerCall(userInfo) {
      return new Promise((resolve, reject) => {
        // Will pass user info once we are ready to collect it
        _registerCall(this.accountId,userInfo).then((res) => {
          this.callData = res.data
          this.keepAliveTimer = setInterval(this.keepAlive,5000)
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    keepAlive() {
      return new Promise((resolve, reject) => {
        _keepAlive(this.accountId,this.callData.id).then((res) => {
          this.callData = res.data
          if (res.data.token) {
            this.connected = true
          }
          resolve(res.data)
        }).catch((err) => {
          this.failOut = true
          reject(err)
        })
      })
    },
    getCall() {
      return new Promise((resolve, reject) => {
        _getCall(this.accountId,this.callData.id).then((res) => {
          this.callData = res.data
          resolve(res.data)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    endCall() {
      clearInterval(this.keepAliveTimer)
      return new Promise((resolve, reject) => {
        _endCall(this.accountId,this.callData.id)
            .then((res) => {
              this.callData = res.data
              resolve(res.data)
            }).catch((err) => {
          reject(err)
        })
      })
    },
    bailCall() {
      clearInterval(this.keepAliveTimer)
      this.keepAliveTimer = null
    }
  }
}
