<template>
  <b-modal
      :active="open"
      full-screen
      trap-focus
      destroy-on-hide
      :can-cancel="false"
  >
    <slot></slot>
  </b-modal>
</template>

<script>
export default {
  name: "mobileWrapper",
  props: {
    open: Boolean
  }
}
</script>
