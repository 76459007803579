<template>
  <b-button
      id="gradient-start-button"
      :class="open ? 'button-is-open' : 'button-is-closed'"
      :style="open ? 'opacity:0' : 'opacity:1'"
      @click="$emit('open')">
  </b-button>
</template>

<script>
import btnIcon from "../assets/comment-invert.png"

export default {
  name: "startButton",
  props: {
    open: Boolean
  },
  data() {
    return {
      btnIcon
    }
  }
}
</script>

<style lang="scss">
  #gradient-start-button {
    box-sizing: border-box;
    box-shadow: 0 1px 8px 4px rgba(8, 15, 61, 0.25);
    background-image: url("https://assets.hi-there.video/img/VideoChatPreview2x.png"),linear-gradient(180deg, #6154D2 0%, #393B62 100%);
    background-position: center center;
    background-size: 155px;
    align-self: end;
    justify-self: end;
    z-index: 1;
    transition: all .3s ease-in-out;
    width: 128px;
    height: 128px;
    border-radius: 64px;
    border: none;
    &:hover {
      box-shadow: 2px 3px 8px rgba(0,0,0,0.3);
      transform: translate(-2px,-2px) scale(1.05,1.05);
    }
  }
  @media screen and (max-width: 768px) {
    transition: margin 1s ease-in-out;
    #gradient-start-button {
      &.button-is-open {
        margin-bottom: 20px;
        margin-right: 20px;
      }
    }
  }
</style>
