<template>
  <div class="two-column-wrap">
    <div class="col-wrap left-wrap">
      <p class="text-space bottom-space">Instant video chat help</p>
    <ValidationObserver v-slot="{ invalid }">
      <validation-provider name="firstName" rules="required" v-slot="{ errors }">
      <b-field label-position="inside" class="space-bottom" label="First Name" :message="errors[0]" :type="errors[0] ? 'is-danger' : ''">
        <b-input v-model="firstName"></b-input>
      </b-field>
    </validation-provider>
      <validation-provider name="lastName" rules="required" v-slot="{ errors }">
      <b-field label-position="inside" class="space-bottom" label="Last Name" :message="errors[0]" :type="errors[0] ? 'is-danger' : ''">
      <b-input v-model="lastName"></b-input>
    </b-field>
      </validation-provider>
      <validation-provider name="email" rules="required|email" v-slot="{ errors }">
        <b-field label-position="inside" class="space-bottom" label="Email" :message="errors[0]" :type="errors[0] ? 'is-danger' : ''">
        <b-input type="email" placeholder="email@mail.com" v-model="email"></b-input>
      </b-field>
      </validation-provider>
      <validation-provider v-for="field in inputs" :key="field.id" :rules="field.required ? 'required' : ''" v-slot="{errors}">
        <b-field class="space-bottom" label-position="inside" :label="field.displayText" :message="errors[0]" :type="errors[0] ? 'is-danger' : ''">
          <b-input type="textarea" :ref="field.id" :placeholder="field.description" v-model="customFields[field.id]"></b-input>
        </b-field>
      </validation-provider>
      <p style="text-align: center; margin-bottom: 5px; font-size: 12px; font-weight: bold; color:rgba(10,10,10,0.87)">Connection Options</p>
      <div style="display: flex; align-items: center; justify-content: center; margin-bottom: 10px">
        <b-tooltip :label="`Video ${defaults.video ? 'enabled' : 'disabled'}`">
          <b-switch v-model="defaults.video" left-label style="margin-right: 10px"><VideoOn class="default-icons" v-if="defaults.video"></VideoOn><VideoOff class="default-icons" v-else></VideoOff></b-switch>
        </b-tooltip>
        <b-tooltip :label="`Audio ${defaults.audio ? 'enabled' : 'disabled'}`">
          <b-switch v-model="defaults.audio" left-label style="margin-left: 10px"><MicOn class="default-icons" v-if="defaults.audio"></MicOn><MicOff class="default-icons" v-else></MicOff></b-switch>
        </b-tooltip>
      </div>
      <b-tooltip v-show="!supported" multilined style="width: 100%" label="Some video call features my not function properly. We recommend Google Chrome on a computer for the best experience.">
        <p style="color: #E66673; margin-bottom: 10px; text-align: center">Unsupported browser detected</p>
      </b-tooltip>
    <b-button :disabled="invalid && !showOverlay" type="is-dark" expanded size="is-large" class="start-button" @click="handleClick">
      {{ showOverlay ? 'Cancel' : 'Connect' }}</b-button>
      <b-loading :is-full-page="false" :active="showOverlay && !error"></b-loading>
      </ValidationObserver>
    </div>
    <div v-if="mobile" class="col-wrap right-wrap mobile-text-wrap">
      <div class="version-wrap">
        <p class="version-text" v-if="getVersion">Version: {{getVersion}}</p>
      </div>
      <div class="middle-text">
        <p v-for="line in statusMessage.split('\n')">{{line}}</p>
        <p v-if="status === 'bail'">Feel free to</p>
        <b-button v-if="status === 'bail'" style="color: #dbd2f4; font-weight: bold; padding: 0; height:24px; line-height: 24px" type="is-ghost" @click="handleCancel">Leave The Queue</b-button>
        <p v-if="status === 'bail'">and try later or</p>
        <p v-if="status === 'bail'">stay on the line</p>
        <p v-if="status === 'bail'">and we will be</p>
        <p v-if="status === 'bail'">with you shortly</p>
      </div>
      <div style="height: 24px; margin-bottom: 5px"></div>
    </div>
    <div v-else class="col-wrap right-wrap stack-grid">
      <b-image src="https://assets.hi-there.video/img/PreviewAgent.png" class="limit-desktop-height" :style="windowed ? 'border-radius:0' : ''"></b-image>
      <div class="side-image-overlay" :style="windowed ? 'border-radius:0' : ''"></div>
      <div class="middle-text">
        <p v-for="line in statusMessage.split('\n')">{{line}}</p>
        <p v-if="status === 'bail'">Feel free to</p>
        <b-button v-if="status === 'bail'" style="color: #dbd2f4; font-weight: bold; padding: 0; height:24px; line-height: 24px" type="is-ghost" @click="handleCancel">Leave The Queue</b-button>
        <p v-if="status === 'bail'">and try later or</p>
        <p v-if="status === 'bail'">stay on the line</p>
        <p v-if="status === 'bail'">and we will be</p>
        <p v-if="status === 'bail'">with you shortly</p>
      </div>
      <div class="version-wrap">
        <p class="version-text" v-if="getVersion">Version: {{getVersion}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import PreviewAgent from "../assets/PreviewAgent.png"
import VideoOn from "../assets/VideocamOutline.svg"
import VideoOff from "../assets/VideocamOffOutline.svg"
import MicOn from "../assets/MicOutline.svg"
import MicOff from "../assets/MicOffOutline.svg"
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

// No message specified.
extend('email', {
  ...email,
  message: 'Please enter a valid email'
});

// Override the default message.
extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: "welcomeMessage",
  keepAlive: false,
  components: {
    ValidationObserver,
    ValidationProvider,
    VideoOn,
    VideoOff,
    MicOn,
    MicOff
  },
  props: {
    status: String,
    mobile: {
      type: Boolean,
      required: false
    },
    inputs: Array,
    windowed: Boolean,
    supported: Boolean,
  },
  data() {
    return {
      PreviewAgent,
      firstName: "",
      lastName: "",
      email: "",
      customFields:{},
      defaults: {
        video:true,
        audio:true
      },
      showOverlay: false
    }
  },
  computed: {
    error() {
      return this.status === 'failure'
    },
    statusMessage() {
      switch (this.status) {
        case "default":
          return "Ready to Chat!";
        case "connecting":
          return "Connecting...";
        case "delay":
          return `Should be a few \n more seconds...`;
        case "bail":
          return `Sorry about the wait!`
        case "failure":
          return `Sorry, we were \n unable to connect.`;
        default:
          return "Welcome!"
      }
    },
    getVersion() {
      return process.env.VUE_APP_BUILD_VERSION ? process.env.VUE_APP_BUILD_VERSION : null;
    },
  },
  methods: {
    handleCancel() {
      this.showOverlay = false
      this.$emit('cancel')
    },
    handleClick() {
      if (this.showOverlay) {
        this.handleCancel()
      } else {
        this.showOverlay = true
        let customFields = Object.entries(this.customFields).map((val) => {
          return {id: val[0], value: val[1]}
        })
        this.$emit('connect', {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          customFields
        })
      }
    }
  },
  watch: {
    defaults: {
      deep: true,
      handler: function(n) {
        this.$emit('defaults',n)
      }
    }
  }
}
</script>
