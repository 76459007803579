<template>
  <vue-resizable class="stack-grid" :style="windowed ? 'width:100vw !important; height:100vh !important' : 'position:absolute'" :active="windowed ? [] : ['l','lt','t']" :drag-selector="windowed ? null : '#drag-selector-overlay'" :width="makeSize.w" :height="makeSize.h" :min-width="600" :min-height="400" :left="makeSize.l" :top="makeSize.t">
    <div ref="videoTrack" id="video-attach-item">
      <img v-if="!remoteVideo" alt="Customer Service" :src="Placeholder" style="max-width: 150px;" >
    </div>
    <video v-show="share" autoplay id="share-screen-view" ref="shareVideoTrack" width="400px"></video>
    <div ref="localVideoTrack" id="local-video-attach-item">
      <div v-if="!video" style="display: flex; flex-direction: column; align-items: center; justify-content: center">
        <VideoOff style="max-width: 48px; color: white"></VideoOff>
        <p style="color: white">{{identity}}</p>
      </div>
    </div>
    <div class="user-indicator">
        <strong class="user-indicator-name">{{remoteUser}}</strong>
      <div class="user-audio-indicator" :style="`background-color:${remoteAudio ? 'rgba(0,0,0,0.2)' : '#C42231'}`">
        <MicOn v-if="remoteAudio" color="#7CCF9E"></MicOn>
        <MicOff  v-else color="white"></MicOff>
      </div>
    </div>
    <div id="drag-selector-overlay" style="width: 100%; height: 100%; z-index: 0"></div>
    <div class="control-wrapper">
      <b-button class="control-item" @click="handleVideoClick()" :disabled="share">
        <VideoOn class="on-item" v-if="video"></VideoOn>
        <VideoOff class="off-item" v-else></VideoOff>
      </b-button>
      <b-button :disabled="loading" class="control-item" @click="handleAudioClick()">
        <MicOn class="on-item" v-if="mic"></MicOn>
        <MicOff class="off-item" v-else></MicOff>
      </b-button>
      <b-button :disabled="loading || !canScreenShare" @click="handleShareClick()" class="control-item screenshare-control">
        <ShareOn v-if="share"></ShareOn>
        <ShareOff v-else></ShareOff>
      </b-button>
      <b-button :disabled="loading || windowed" @click="maximize = !maximize" class="control-item" style="color: white">
        <Windowed class="default-item" v-if="maximize"></Windowed>
        <FullScreen class="default-item" v-else></FullScreen>
      </b-button>
      <!--      <div class="control-item blank-control-item"></div>-->
      <b-button @click="hangUp" class="control-item" type="is-danger">END</b-button>
    </div>
  </vue-resizable>
</template>

<script>
import VideoOn from "../assets/VideocamOutline.svg"
import VideoOff from "../assets/VideocamOffOutline.svg"
import MicOn from "../assets/MicOutline.svg"
import MicOff from "../assets/MicOffOutline.svg"
import ShareOn from "../assets/ShareScreenStop28Regular.svg"
import ShareOff from "../assets/ShareScreenStart28Regular.svg"
import FullScreen from "../assets/ArrowMaximize28Filled.svg"
import Windowed from "../assets/ArrowMinimize28Filled.svg"
import Placeholder from "../assets/csplaceholder.png"
import {connect, createLocalVideoTrack} from 'twilio-video'
import {isMobile} from "@/helpers/browser"
import {screenshare} from "@/mixins/screenshare";
import {resizable} from "@/mixins/resizable";
import VueResizable from 'vue-resizable'

export default {
  name: "videoPlayer",
  mixins:[screenshare,resizable],
  components: {
    VideoOn,
    VideoOff,
    MicOn,
    MicOff,
    ShareOn,
    ShareOff,
    FullScreen,
    Windowed,
    VueResizable
  },
  props: {
    token: String,
    identity: String,
    roomName: String,
    localUser: String,
    forceFail: Boolean,
    windowed: Boolean,
    defaults: Object
  },
  data() {
    return {
      localTracks: {
        video: null,
        audio: null,
        screen: null,
      },
      selectedAudioTrack:null,
      selectedVideoTrack:null,
      selectedScreenShare:null,
      devices: [],
      video: true,
      mic: true,
      share: false,
      loading: false,
      activeRoom: null,
      error: null,
      remoteAudio: false,
      remoteVideo: false,
      remoteShare: false,
      localReconnect: false,
      localParticipant: false,
      remoteUser: "Customer Service",
      Placeholder
    }
  },
  methods: {
    connectToChat() {
      // Set up connection defaults
      this.loading = true
      const token = this.token;
      let connectOptions = {
        name: `remote&${this.identity}`,
        audio: true,
        video: this.defaults.video,
      };
      if (isMobile) {
        connectOptions.bandwidthProfile = {video:{maxSubscriptionBitrate: 2500000}}
      }
      // Leave room (if you're in one) before trying to join
      this.leaveRoomIfJoined();
      connect(token, connectOptions).then((room) => {
        // Set active room for ref
        this.activeRoom = room;
        // Connect local tracks as needed
        this.participantConnected(room.localParticipant,true)
        // Hook up the remote participant if they exist
        room.participants.forEach((participant) => {
          this.participantConnected(participant,false)
        });
        // Set up room and window listeners
        room.on("participantConnected", (participant)=>this.participantConnected(participant,false));
        room.on("participantDisconnected", this.participantDisconnected);
        window.addEventListener("beforeunload", () => this.activeRoom.disconnect())
        window.addEventListener("pagehide", this.tidyUp())
      }).catch(error => {
        console.error(error)
      })
    },
    participantConnected(participant,isLocal) {
      console.log('Participant connected:',participant,isLocal)
      if (!isLocal) {
        this.remoteUser = participant.identity
        this.remoteVideo = !(participant.videoTracks.size === 0)
      }
      // Publish all tracks for participant
      participant.tracks.forEach(trackPublication => {
        this.trackPublished(trackPublication,isLocal)
      })
      // Set up participant listeners
      participant.on("trackPublished",(trackPublication)=>this.trackPublished(trackPublication,isLocal))
      participant.on("trackUnpublished",(trackPublication)=>this.trackUnpublished(trackPublication,isLocal))
      participant.on("trackDisabled",()=>{
        if(!isLocal) {
          this.remoteAudio = false
        }
      })
      participant.on("trackEnabled",()=>{
        if(!isLocal) {
          this.remoteAudio = true
        }
      })
      if (isLocal && this.loading) {
        this.loading = false
      }
    },
    trackPublished(trackPublication,isLocal) {
      console.log('Track published:',trackPublication,isLocal)
      let container = isLocal ? this.$refs.localVideoTrack : this.$refs.videoTrack
      if (!isLocal && trackPublication.kind === "video") {
        this.remoteVideo = true
      } else if (!isLocal && trackPublication.kind === "audio") {
        this.remoteAudio = trackPublication.isTrackEnabled
      }
      const trackSubscribed = async (track, pub) => {
        if(container.getElementsByTagName(pub.kind)[0]) {
          container.getElementsByTagName(pub.kind)[0].remove()
        }
        container.appendChild(track.attach())
      }
      if (trackPublication.track) {
        trackSubscribed(trackPublication.track,trackPublication)
      }
      if(isLocal && !this.defaults.audio) {
        this.muteAudio()
      }
      trackPublication.on("subscribed", (track) => trackSubscribed(track,trackPublication))
    },
    trackUnpublished(trackPublication,isLocal) {
      console.log('Track unpublished:',trackPublication,isLocal)
      if (isLocal) {
        this.$refs.localVideoTrack.getElementsByTagName("video")[0].remove()
      } else {
        this.$refs.videoTrack.getElementsByTagName("video")[0].remove()
        this.remoteVideo = false
      }
    },
    leaveRoomIfJoined() {
      if (this.activeRoom) {
        this.activeRoom.disconnect();
      }
    },
    participantDisconnected() {
      this.hangUp()
    },
    tidyUp() {

      return function (event) {
        if (event.persisted) {
          return;
        }
        if (this.activeRoom) {
          this.activeRoom.disconnect();
          this.activeRoom = null;
        }
      };
    },
    unmuteAudio() {
      this.activeRoom.localParticipant.audioTracks.forEach(track => {
        track.track.enable();
      });
    },
    unmuteVideo() {
      createLocalVideoTrack().then(track => {
        // const localMediaContainer = this.$refs.localVideoTrack.$el;
        // localMediaContainer.appendChild(track.attach());
        this.localReconnect = true
        return this.activeRoom.localParticipant.publishTrack(track);
      })
    },
    muteAudio() {
      this.activeRoom.localParticipant.audioTracks.forEach(track => {
        track.track.disable();
      });
    },
    muteVideo() {
      const track = [...this.activeRoom.localParticipant.videoTracks.values()][0].track
      track.stop();
      track.detach().forEach(element => {
        element.remove();
      });
      this.activeRoom.localParticipant.unpublishTrack(track);
      },
    hangUp() {
      if (this.activeRoom) {
        this.activeRoom.localParticipant.tracks.forEach(function (track) {
          track.track.stop()
        });
      }
      this.tidyUp()
      this.$emit('ended')
    },
    handleVideoClick() {
      this.video = !this.video
      if (this.video) {
        this.unmuteVideo()
      } else this.muteVideo()
    },
    handleAudioClick() {
      this.mic = !this.mic
      if (this.mic) {
        this.unmuteAudio()
      } else this.muteAudio()
    },
    handleShareClick() {
      this.share=!this.share
      if (this.share) {
        this.startShare()
      } else this.endShare()
    },
  },
  mounted() {
    console.log('mounted',this.defaults)
    this.video = this.defaults.video
    this.mic = this.defaults.audio
    this.$nextTick(function () {
      this.connectToChat()
    })
  },
  beforeDestroy() {
    this.hangUp()
  },
  watch: {
    forceFail:function(n) {
      if (n) {
        this.hangUp()
      }
    }
  }
}
</script>
