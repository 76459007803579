import {sha512} from "js-sha512";

export class WidgetLoginHelper {
    public static createLoginHash(accountId: string, apiKey: string): string  {
        const date = new Date();
        const dateParts = date.toISOString().split('T');
        const dateOnly = dateParts[0].replaceAll("-", "")
        const time =  date.toISOString().split('T')[1];
        const timeParts = time.split(':')
        const hour = timeParts[0]
        const minute = timeParts[1]
        const timeOnly = hour + minute
        const rawKey = accountId + ":" + apiKey + ":" + dateOnly + timeOnly
        return sha512(rawKey);
    }
}
